import * as React from 'react'
import Layout from '../../components/Layout/Layout'
import Banner from '../../components/Banner/Banner'
import Button from '../../components/Button/Button'
import Title from '../../components/Title/Title'
import Text from '../../components/Text/Text'
import Underline from '../../components/Underline/Underline'
import { Helmet } from 'react-helmet'

function ChildhoodPain() {
    return (
        <Layout>
            <Helmet>
                <title>Pain in Childhood and Adolescence - Conditions we treat | Brixworth Osteopathic Clinic</title>
            </Helmet>
            <Title blue={true}>
                <h1>Pain in Childhood and Adolescence</h1>
            </Title>
            <section>
                <Text>
                    <Underline>
                        <h2>Keeping Your Child Healthy</h2>
                    </Underline>
                    <p>Every parent’s priority is to provide the best environment to support their child’s health as they develop and grow. Osteopathic practice is safe and effective and research has shown that people trust their osteopath and are extremely satisfied by their experience of osteopathic care. This provides parents confidence in the level of care their children can expect to receive.</p>
                    <p>Children’s requirements will differ depending on the stage of their development. As they grow from infants, toddlers, children to teenagers, your osteopath will consider your child’s individual needs, age and stage of development, and adapt their advice and treatment accordingly.</p>
                </Text>
                <Text>
                    <Underline>
                        <h2>How Can an Osteopath Help?</h2>
                    </Underline>
                    <p>Parents visit osteopaths for a range of reasons to support their child’s health. Children, like adults, can be affected by general joint and muscle issues, which is one of the reasons people visit an osteopath. Parents will also take their children to visit an osteopath for a variety of other health reasons that may benefit from osteopathic care.</p>
                    <p>As osteopathic care is based on the individual needs of the patient, it will vary depending on your child’s age and the diagnosis. Osteopaths generally use a wide range of gentle hands-on techniques that focus on releasing tension, improving mobility and optimising function. This is often used together with exercise and helpful advice. Some osteopaths have been trained in very gentle techniques which are particularly suitable to assess and treat very young children, including new-borns. You do not need to consult your GP before you visit an osteopath, although you may wish to do so.</p>
                </Text>
            </section>
            <section style={{backgroundColor: 'var(--primary-800)', color: 'white'}}>
                <Banner>
                    <h2>Make an appointment with us.</h2>
                    <Button external={true} type="neutralOutline" to="https://eubookings.nookal.com/bookings/location/GGNJC">Book online today</Button>
                </Banner>
            </section>
        </Layout>
    )
}

export default ChildhoodPain